<template>
    <div
        class="container content-width step android"
        :class="{ 'step-en': $i18n.locale === 'en', 'step-ja': $i18n.locale === 'ja' }"
    >
        <div class="block"></div>

        <div class="ts-measure ts-measure-center">
            <h1 class="page-title">{{ $t("title") }}</h1>
        </div>

        <div id="HowToInstall" class="anchor"></div>

        <div class="ts-measure-tight">
            <h2>{{ $t("install.title") }}</h2>
            <p class="ts-lead ts-quiet">{{ $t("install.text") }}</p>
        </div>

        <div class="block-md"></div>

        <div class="grid grid-50 grid-list vertical-align-start">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number">01</h3>
                    <img height="34" src="@/assets/images/circle-pad-finger.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3 v-html="$t('install.step01.title')"></h3>
                </div>
            </div>
            <div class="grid-item">
                <div class="step-img step-img-01">
                    <img
                        width="400"
                        :src="imgSrc('install-01.png')"
                        :alt="$t('install.step01.title')"
                    />
                </div>
            </div>
        </div>
        <div class="grid grid-50 grid-list vertical-align-start">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number">02</h3>
                    <img height="34" src="@/assets/images/circle-pad-finger.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3 v-html="$t('install.step02.title')"></h3>
                </div>
            </div>
            <div class="grid-item">
                <div class="step-img step-img-02">
                    <img
                        width="400"
                        :src="imgSrc('install-02.png')"
                        :alt="$t('install.step02.title')"
                    />
                </div>
            </div>
        </div>
        <div class="grid grid-50 grid-list vertical-align-start">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number">03</h3>
                    <img height="34" src="@/assets/images/qr-code-scan.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3 v-html="$t('install.step03.title')"></h3>
                    <p class="ts-underline ts-bold" v-html="$t('install.step03.text')"></p>
                </div>
            </div>
            <div class="grid-item">
                <div class="step-img step-img-03">
                    <img
                        width="400"
                        :src="imgSrc('install-03.png')"
                        :alt="$t('install.step03.title')"
                    />
                </div>
            </div>
        </div>
        <div class="grid grid-50 grid-list vertical-align-start">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number">04</h3>
                    <img height="34" src="@/assets/images/circle-pad-finger.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3 v-html="$t('install.step04.title')"></h3>
                    <p class="ts-quiet" v-html="$t('install.step04.text')"></p>
                </div>
            </div>
            <div class="grid-item">
                <div class="step-img step-img-04">
                    <img
                        width="400"
                        :src="imgSrc('install-04.png')"
                        :alt="$t('install.step04.title')"
                    />
                </div>
            </div>
        </div>
        <div class="grid grid-50 grid-list vertical-align-start">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number" v-html="$t('install.step05.tag')"></h3>
                    <img height="34" src="@/assets/images/form-icon.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3 v-html="$t('install.step05.title')"></h3>
                    <p class="ts-quiet" v-html="$t('install.step05.text')"></p>
                </div>
            </div>
            <div class="grid-item">
                <div class="step-img step-img-05">
                    <img
                        width="400"
                        :src="imgSrc('install-05.png')"
                        :alt="$t('install.step05.title')"
                    />
                </div>
            </div>
        </div>

        <div id="HowToUse" class="anchor"></div>

        <div class="ts-measure-tight">
            <h2>{{ $t("use.title") }}</h2>
            <p class="ts-lead ts-quiet" v-html="$t('use.text')"></p>
        </div>

        <div class="block-md"></div>

        <div class="grid grid-50 grid-list vertical-align-start">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number">01</h3>
                    <img height="34" src="@/assets/images/circle-pad-finger.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3 v-html="$t('use.step01.title')"></h3>
                    <p class="ts-quiet" v-html="$t('use.step01.text')"></p>
                </div>
            </div>
            <div class="grid-item">
                <div class="step-img step-img-07">
                    <img width="400" :src="imgSrc('use-01.png')" :alt="$t('use.step01.title')" />
                </div>
            </div>
        </div>
        <div id="APN" class="grid grid-50 grid-list vertical-align-start">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number">02</h3>
                    <img height="34" src="@/assets/images/circle-pad-finger.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3 v-html="$t('use.step02.title')"></h3>
                </div>
            </div>
            <div class="grid-item">
                <div class="step-img step-img-08">
                    <img width="400" :src="imgSrc('use-02.png')" :alt="$t('use.step02.title')" />
                </div>
            </div>
        </div>
        <div class="grid grid-50 grid-list vertical-align-start">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number">03</h3>
                    <img height="34" src="@/assets/images/circle-pad-finger.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3 v-html="$t('use.step03.title')"></h3>
                </div>
            </div>
            <div class="grid-item">
                <div class="step-img step-img-09">
                    <img width="400" :src="imgSrc('use-03.png')" :alt="$t('use.step03.title')" />
                </div>
            </div>
        </div>
        <div class="grid grid-50 grid-list vertical-align-start">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number">04</h3>
                    <img height="34" src="@/assets/images/circle-pad-finger.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3 v-html="$t('use.step04.title')"></h3>
                </div>
            </div>
            <div class="grid-item">
                <div class="step-img step-img-10">
                    <img width="400" :src="imgSrc('use-04.png')" :alt="$t('use.step04.title')" />
                </div>
            </div>
        </div>
        <div class="grid grid-50 grid-list vertical-align-start">
            <div class="grid-item">
                <div class="hiw-step-header">
                    <h3 class="hiw-step-number">05</h3>
                    <img height="34" src="@/assets/images/circle-pad-finger.svg" />
                </div>
                <div class="hiw-step-body">
                    <h3 v-html="$t('use.step05.title')"></h3>
                </div>
            </div>
            <div class="grid-item">
                <div class="step-img step-img-11">
                    <img width="400" :src="imgSrc('use-05.png')" :alt="$t('use.step05.title')" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    meta() {
        return this.$generateMeta({
            title: this.$i18n.t("meta.set_up_android.title"),
            description: this.$i18n.t("meta.set_up_android.description"),
        })
    },
    data() {
        return {
            imageToggle: "balance",
        }
    },
    methods: {
        imgSrc(fileName) {
            const locale = this.$i18n.locale
            return require(`@/assets/images/screenshot/set-up-android/${locale}/${fileName}`)
        },
    },
    i18n: {
        messages: {
            en: {
                title: "Set Up Guide for Android",
                install: {
                    title: "How To Install Stork Mobile eSIM",
                    text:
                        "For Pixel 5 running Android 13. The actual text or screen display may vary depending on the Android version and the phone model.",
                    step01: {
                        title: "Navigate to “Network and Internet”, then tap “+” next to SIMs",
                    },
                    step02: {
                        title: "Tap “Download a SIM instead?”",
                    },
                    step03: {
                        title: "Scan QR code",
                        text: "* Internet connection required",
                    },
                    step04: {
                        title: "Tap “Download”",
                        text:
                            "After the download is complete, tap on “Settings” displayed at the bottom right of the screen.",
                    },
                    step05: {
                        tag: "Optional",
                        title: "Change the name to “Stork Mobile”",
                        text:
                            "From “SIMs”, tap on the downloaded eSIM named “SORACOM”. You can change the name from the pen (edit) icon at the top right.",
                    },
                },
                use: {
                    title: "How To Use Stork Mobile eSIM For Mobile Data",
                    text: "Follow these steps after you arrive at your travel destination.",
                    step01: {
                        title: "Turn on “Use SIM” for Stork Mobile",
                        text:
                            "Depending on the device, it is possible to activate both one SIM and one eSIM at the same time, or two eSIMs simultaneously.",
                    },
                    step02: {
                        title: "Enable both “Mobile data” and “Roaming”",
                    },
                    step03: {
                        title:
                            "Tap “Access point names”, and create a new APN (Access Point Name) from “+”",
                    },
                    step04: {
                        title:
                            "Enter “Stork Mobile” for the Name and “stork” (all in lowercase) for the APN, then Save from the menu at the top right.",
                    },
                    step05: {
                        title: "Select the APN you’ve just saved",
                    },
                },
                amp: {
                    next: {
                        title: "stork mobileについて",
                    },
                },
            },
            ja: {
                title: "Set Up Guide for Android",
                install: {
                    title: "eSIM のインストール方法",
                    text:
                        "ここではPixel 5（Android 13）での設定方法を解説します。Androidのバージョンや機種によって表示されるテキストやインターフェイスが多少異なる場合があります。",
                    step01: {
                        title:
                            "設定から「ネットワークとインターネット」に進み、SIMの右にある「+」をタップ",
                    },
                    step02: {
                        title: "「SIMをダウンロードしますか？」をタップ",
                    },
                    step03: {
                        title: "QRコードを読み取る",
                        text: "* インターネット環境が必要です。",
                    },
                    step04: {
                        title: "「ダウンロード」をタップ",
                        text:
                            "ダウンロードが完了後、画面右下に表示される「設定」をタップしてください。",
                    },
                    step05: {
                        tag: "任意",
                        title: "名前を「Stork Mobile」に変更しましょう",
                        text:
                            "ネットワークとインターネットの「SIM」からダウンロードしたSORACOMという名称のeSIMをタップし、右上のペン（編集）アイコンから名前を変更できます。",
                    },
                },
                use: {
                    title: "eSIMをデータ通信に使う",
                    text: "旅行先に到着したら、次の手順にしたがって設定してください。",
                    step01: {
                        title: "Stork Mobileをタップし、「SIMを使用」をオン",
                        text:
                            "デバイスによって物理SIMとeSIMを同時に有効にできるものや、2つのeSIMを同時に有効にできるものがあります。",
                    },
                    step02: {
                        title: "「モバイルデータ」と「ローミング」を両方オン",
                    },
                    step03: {
                        title:
                            "「アクセスポイント名」をタップし、その後「+」から新規にAPN（アクセスポイント名）を作成",
                    },
                    step04: {
                        title:
                            "名前に“Stork Mobile”、APNに“stork”（すべて小文字）を入力し、右上のメニューから「保存」を選択",
                    },
                    step05: {
                        title: "保存したAPNを選択",
                    },
                },
                amp: {
                    next: {
                        title: "stork mobileについて",
                    },
                },
            },
        },
    },
}
</script>
